<template>
  <div class="home">
    <!-- <img alt="Vue logo" src=""> -->
    <Draw></Draw>
    <!-- <Prediction></Prediction> -->
    <Features></Features> 
     <Footer></Footer>
  </div>
</template>

<script>
  import Draw from '@/components/Draw.vue'
  //import Prediction from '@/components/Prediction.vue'
  import Features from '@/components/Features.vue'
  import Footer from '@/components/Footer.vue'
  export default {
    name: 'Home',
    components: {
      Draw,
     // Prediction,
      Features,
      Footer
    },
    methods: {
    

    }
  }

</script>
<style>

a{
  text-decoration: none;
  color:black;
}
</style>